<template>
    <el-dialog :visible.sync="dialogFormVisible" width="90%" v-mobile-dialog class="program-view"
               :close-on-click-modal="false" :before-close="handleClose">

        <div slot="title"><b class="text-info">({{ single.id }}){{ single.subject }}</b></div>
        <div v-mobile-class>
            <el-row :gutter="20">
                <el-col :span="24" class="program-info-wrapper">
                    <div class="program-picture-wrapper">
                        <div class="picture">
                            <img :src="single.picture_url" alt="">
                        </div>
                    </div>
                    <el-row :gutter="20" class="program-info-details">
                        <el-col :span="24" :lg="12">
                            <div class="d-flex dd-block">
                                <div class="dd-label">时间:</div>
                                <div class="dd-content">{{ single.good_time }}
                                    {{ single.status == 1 ? '直播中' : (single.status >= 2 ? '已结束' : '未开始') }}
                                </div>
                            </div>
                            <div class="d-flex dd-block">
                                <div class="dd-label">城市:</div>
                                <div class="dd-content">{{ single.city_name }}|{{ single.city_english }}</div>
                            </div>
                            <div class="d-flex dd-block">
                                <div class="dd-label">部门:</div>
                                <div class="dd-content text-ellipsis overflow-hidden">{{ single.department_name }}
                                </div>
                            </div>
                            <div class="d-flex dd-block">
                                <div class="dd-label">主讲人:</div>
                                <div class="dd-content">{{ single.teacher_name }}</div>
                            </div>
                            <div class="d-flex dd-block">
                                <div class="dd-label">开放:</div>
                                <div class="dd-content">
                                    {{
                                        single.is_public == '1' ? "公开" : (single.is_public === '2' ? "私有" : "内部")
                                    }}
                                    <span
                                        v-if="single.security_group_name">( 白名单:{{
                                            single.security_group_name
                                        }})</span>
                                </div>
                            </div>
                            <div class="d-flex dd-block">
                                <div class="dd-label">直播连接:</div>
                                <div class="dd-content">
                                    <el-popover
                                        placement="right"
                                        width="auto"
                                        trigger="hover">
                                        <qrcode-vue :value="play_url" :size="240" level="H"/>
                                        <el-button type="text" slot="reference" icon="el-icon-menu"
                                                   style="color: brown;padding: 0">二维码
                                        </el-button>
                                    </el-popover>
                                    |
                                    <a :href="play_url" target="_blank">{{ play_url }}</a>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="24" :lg="12">
                            <div class="d-flex dd-block">
                                <div class="dd-label">结束动作:</div>
                                <div class="dd-content">{{ single.close_action_name }}</div>
                            </div>
                            <div class="d-flex dd-block">
                                <div class="dd-label">推流:</div>
                                <div class="dd-content">
                                    {{ single.rtmp }}/{{ single.source }}
                                    <span v-if="single.source2" class="text-info"> (和 {{ single.source2 }})</span>
                                </div>
                            </div>
                            <div class="d-flex dd-block">
                                <div class="dd-label">观看密码:</div>
                                <div class="dd-content">{{ single.passcode }}</div>
                            </div>
                            <div class="d-flex dd-block">
                                <div class="dd-label">评论:</div>
                                <div class="dd-content">{{ single.is_comment_disabled ? "关闭" : "允许" }}</div>
                            </div>
                            <div class="d-flex dd-block">
                                <div class="dd-label">栏目:</div>
                                <div class="dd-content">{{ single.section_name }}</div>
                            </div>
                            <div class="d-flex dd-block">
                                <div class="dd-label">监控连接:</div>
                                <div class="dd-content"><a :href="console_url" target="_blank">{{ console_url }}</a>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="24" class="mt-4">
                    <div class="bg-light p-3">
                        <div class="dd-content" v-html="single.description"></div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="$emit('cancel')">关 闭</el-button>
        </div>
    </el-dialog>
</template>

<script>

import QrcodeVue from 'qrcode.vue'

export default {
    name: "program-view",
    data() {
        return {
            dialogFormVisible: false,
        }
    },
    components: {QrcodeVue},
    props: {
        view_mode: {
            type: Boolean,
            default() {
                return false;
            }
        },
        single: {
            type: Object,
            default() {
                return {};
            }
        },
    },
    watch: {
        view_mode: {
            handler(newVal) {
                this.dialogFormVisible = newVal;
            }
        },
    },
    methods: {
        handleClose(done) {
            done();
            this.$emit('cancel');
        },
    },
    mounted() {
    },

    computed: {
        mobile() {
            return this.$isMobile();
        },
        play_url() {
            if (this.single && this.single.play_url) {
                let info = this.$urlParse(this.single.play_url);
                if (info.origin !== window.location.origin) {
                    return this.single.play_url.replace(info.origin, window.location.origin);
                }
                return this.single.play_url;
            }
            return '';
        },
        console_url() {
            if (this.single && this.single.console_url) {
                let info = this.$urlParse(this.single.console_url);
                if (info.origin !== window.location.origin) {
                    return this.single.console_url.replace(info.origin, window.location.origin);
                }
                return this.single.console_url;
            }
            return '';
        },
    }
}
</script>

<style lang="scss" scoped>

.program-view {

    .program-info-wrapper {
        display: flex;

        .program-picture-wrapper {
            flex: 0 1 1px;
            margin-right: 10px;

            .picture {
                width: 100%;
                border-radius: 5px;
                border: 1px solid #000;

                img {
                    border-radius: 5px;
                    max-height: 154px;
                    border: 1px solid white
                }
            }
        }
        .program-info-details {
            flex: 1 1 60%;

        }
    }

    .mobile {
        .program-info-wrapper {
            flex-direction: column;

            .program-picture-wrapper {
                margin-bottom: 20px;

                .picture {
                    text-align: center;
                    img {
                        border-radius: 5px;
                        max-height: unset;
                        max-width: 100%;
                    }
                }

            }
            .program-info-details {
                flex: 1 1 100%;
                display: block;
            }
        }
    }

    .dd-block {
        border-bottom: solid 1px #b2ebf2;

        .dd-label {
            width: 80px;
            text-align: left;
            font-weight: bold;
            display: inline-block;
            line-height: 30px;
            background-color: aliceblue;
            color: #0097A7;
            padding-left: 5px;
        }

        .dd-content {
            flex: 1;
            text-align: left;
            margin-left: 10px;
            display: inline-block;
            line-height: 30px;
            overflow: auto;
        }
    }
}

</style>
