<template>
    <section class="h-100">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :front_extra="extra_filters"
                     :model="model"
                     @reload="handleReload"
                     @edit="handleEdit"
                     :btn_copy = "can_copy"
                     @copy="handleCopy"
                     :btn_add = "can_add"
                     :btn_delete="can_delete"
                     :btn_edit="can_edit"

        >
            <el-button  slot="footer" type="warning" size="medium" icon="el-icon-download" @click="downloadReport">下载直播节目清单</el-button>
        </front-table>
        <!--编辑界面-->
        <edit-dialog :edit_mode="edit_mode" :model="model" :single="single" :fields="fields" dual
                     @success="handleSuccess" @cancel="handleCancel"></edit-dialog>

        <div class="clearfix"></div>

    </section>
</template>

<script>
    import EditDialog from "../components/edit-dialog";
    import FrontTable from "../components/front-table";
    import FrontMixins from "../common/mixins/front-mixins";
    import AdminAdmin from "./mixins/admin-admin-mixin"

    export default {
        mixins: [FrontMixins,AdminAdmin],
        components: {FrontTable, EditDialog},
        name:'report-hours',
        methods: {
            downloadReport() {
                this.$api('Admin.Access.Report',{data:this.search.extra},1,1).then(res=>{
                    const fileURL = window.URL.createObjectURL(res.data);
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", '直播节目观看人员');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                });
            }
        }
    }

</script>

<style lang="scss">

</style>
