<template>
    <section class="h-100">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     @reload="handleReload"
                     @review="handleReview"
                     :btn_review="true"
                     :btn_add = "false"
                     :btn_delete="false"
                     :btn_edit="false"
                     :front_extra="extra_filters"

        >
            <div class="pull-right">
                <calendar-picker-filter :value="search.date_range" @change="handleCalendarChange" ></calendar-picker-filter>
            </div>

            <div slot="footer" class="pull-left">
                <el-button size="small" type="default" @click="handleMakeReport" icon="el-icon-refresh">生成报表</el-button>
                <el-button size="small" type="success" @click="handleDownloadReport" icon="el-icon-download" v-if="report_date">下载{{report_date}}报表</el-button>
            </div>

        </front-table>
        <!--编辑界面-->
        <log-view :single="single" :view_mode="view_mode" @cancel="view_mode = false">
        </log-view>
    </section>
</template>

<script>
    import FrontTable from "../components/front-table";
    import FrontMixins from "../common/mixins/front-mixins";
    import LogView from "../components/log-view";
    import CalendarPickerFilter from "../components/calendar-picker-filter";

    export default {
        mixins: [FrontMixins],
        name: 'admin-admin',
        components: {LogView, FrontTable, CalendarPickerFilter},
        data(){
            return {
                report_date: '',
                timer:0,
            }
        },
        created() {
            this.model =  'Admin.' + this.$route.meta.model;
            this.search.date_range = [
                ((new Date()).Format('yyyy-MM-dd 00:00'))
                , ((new Date()).Format('yyyy-MM-dd 23:59'))
            ];
            this.search.use_date_range =true;
            this.checkReport();
        },
        methods:{
            checkReport(){
                this.$api('Admin.SysLog.CheckReport', {}).then(res=>{
                   if (res.data.success) {
                          this.report_date = res.data.data;
                     } else {
                          this.report_date = '';
                   }
                });
            },
            handleMakeReport(){
                this.$api('Admin.SysLog.MakeReport', {},1).then((res)=>{
                    this.report_date = res.data.data;
                    this.$notice.success('生成报表成功，点击下载按钮下载报表');
                });
            },
            handleDownloadReport(){
                this.$api('Admin.SysLog.DownloadReport', {},1,1).then((res)=>{
                    this.$download(res.data,"syslog.csv");
                });
            },
        }
    }

</script>

<style lang="scss">

</style>
