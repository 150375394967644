<template>
    <section class="h-100">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :front_extra="extra_filters"
                     :model="model"
                     @reload="handleReload"
                     @edit="handleEdit"
                     :btn_copy="can_copy"
                     @copy="handleCopy"
                     :btn_add="can_add"
                     :btn_delete="can_delete"
                     :btn_edit="can_edit"
                     @review="handleReview"
                     :btn_review="true"

        >
        </front-table>
        <!--编辑界面-->
        <edit-dialog :edit_mode="edit_mode" :model="model" :single="single" :fields="fields" dual
                     @success="handleSuccess" @cancel="handleCancel"></edit-dialog>

        <teacher-view :single="single" :view_mode="view_mode" @cancel="view_mode = false"></teacher-view>
        <div class="clearfix"></div>

    </section>
</template>

<script>
import EditDialog from "../components/edit-dialog";
import FrontTable from "../components/front-table";
import FrontMixins from "../common/mixins/front-mixins";
import AdminAdmin from "./mixins/admin-admin-mixin"
import TeacherView from "@/components/teacher-view";

export default {

    mixins: [FrontMixins, AdminAdmin],
    components: {FrontTable, EditDialog, TeacherView},
}

</script>

<style lang="scss">

</style>
