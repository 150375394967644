<template>
    <section class="h-100">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :front_extra="extra_filters"
                     :model="model"
                     @reload="handleReload"
                     @edit="handleEdit"
                     :btn_copy = "can_copy"
                     @copy="handleCopy"
                     :btn_add = "can_add"
                     :btn_delete="can_delete"
                     :btn_edit="can_edit"

        >
            <el-button slot="footer" size="medium" type="success" icon="el-icon-download" @click="downloadCommentReport">下载报告</el-button>
        </front-table>
        <!--编辑界面-->
        <edit-dialog :edit_mode="edit_mode" :model="model" :single="single" :fields="fields" dual
                     @success="handleSuccess" @cancel="handleCancel"></edit-dialog>
        <div class="clearfix"></div>
    </section>
</template>

<script>
    import EditDialog from "../components/edit-dialog";
    import FrontTable from "../components/front-table";
    import FrontMixins from "../common/mixins/front-mixins";
    import AdminAdmin from "./mixins/admin-admin-mixin"

    export default {
        name:"admin-comment",
        mixins: [FrontMixins,AdminAdmin],
        components: {FrontTable, EditDialog},
        methods:{
            downloadCommentReport(){
                const selected = this.getExtra('S');
                if (!selected) return;


                this.$api('Admin.ReportProgram.Comment',{id:selected.value},1,1).then(res=>{
                    const fileURL = window.URL.createObjectURL(
                        res.data
                    );
                    const fileLink = document.createElement("a");

                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", (selected.label)+'-评论列表节目.xlsx');
                    document.body.appendChild(fileLink);

                    fileLink.click();
                });
            }
        }
    }

</script>

<style lang="scss">

</style>
